
import {Deserializable} from './deserialize';

export type Role = 'user' | 'admin' ;

export interface IRegister {
  name: string,
  identifier: string,
  password: string
}

export interface IUser {
  _id?: string;
  email?: string;
  updated_at?: string;
  selected?: boolean;
  created_at?: string;
  coupon?: {
    coupon?: string;
    token?: string;
  };
  address?: {
    street?: string;
    city?: string;
    zip?: string;
    country?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    state?: string;
    type?: string;
    eMail?: string;
    company?: string;

  };
  roles?: Role[];
  budgets?:string[];
  budgetIds?: string[];
}

export class User implements IUser, Deserializable {
  _id?: string;
  email?: string;
  updated_at?: string;
  selected?: boolean;
  created_at?: string;
  coupon?: {
    coupon?: string;
    token?: string;
  };
  address?: {
    street?: string;
    city?: string;
    zip?: string;
    country?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    state?: string;
    type?: string;
    eMail?: string;
    company?: string;
  };
  roles?: Role[];
  budgets?:string[];
  budgetIds?: string[];

  deserialize(input: IUser) {
    if (input) {
      Object.assign(this, input);
    }
    return this;
  }
}